import React from 'react'
import styles from '../styles/Benefits.module.css'
import AnalyseImage from '../images/analyse.svg'

export default () => {
  return (
    <div className={styles.wrapper}>
      <h2>Etäluettu ja yhteen paikkaan koostettu tieto luo tuottavuutta</h2>
      <img loading="lazy" className={styles.illustration} src={AnalyseImage} alt="SensorMonitor - tiedon louhintaa"/>
      <p>Langattomat IoT-sensorit lähettävät keräämänsä tiedon Digitan LoRaWAN-verkon kautta palvelimellemme, josta sen saa tarkasteltavaksi esim. mobiilisovelluksen avulla. Mittauskäyntejä kohteeseen ei tarvitse enää tehdä ja mittausvirheet vähenevät. Kaikkien sensoreiden keräämä data ohjataan yhdelle alustalle, josta tietoa voi tulkita yhden käyttäjätunnuksen takaa. Yhteen paikkaan koostettu tieto poistaa turhaa työtä.</p>
      <h4>Turvallisempi</h4>
      <p>Verrattuna suoraan lähiverkon kautta Internetiin yhdistettäviin sensoreihin (tai IoT-laitteisiin ylipäänsä) käyttämämme LoRaWAN-laitteisto on tietoturvallisempaa. Tämä siksi, että laitteiden käyttäessä eri verkkoa kuin esim. kodin tietokoneet, ei hakkeri voi käyttää laitteita porttina päästäkseen käsiksi niihin. Tämä on todellinen hyöty, koska suoraan Internettiin kytkettävät laitteet ovat yleensä ottaen huonossa maineessa tietoturva-asioissa. Lisäksi käyttämämme laitteet ovat immuuneja sähkökatkoille, koska ovat paristo-/akkukäyttöisiä eivätkä tarvitse lähiverkkoa toimiakseen. Rakennus voi pimentyä, mutta sensoreista saadaan kyllä tietoa ulos!</p>
      <h4>Yhdistä käytössä olevat sensorit ja alustat</h4>
      <p>Järjestelmäämme voi liittää monien eri valmistajien sensoreita ja järjestelmästä voidaan lähettää tietoa myös toisiin alustoihin. Voit käyttää mahdollisesti jo olemassa olevaa mittauslaitteistoasi ja lisätä siihen uusia langattomia sensoreita.</p>
      <h4>Sensorit käteen -palvelu</h4>
      <p>Tuotamme palvelumme pääasiassa “sensorit käteen” mallilla. Suunnittelemme mittausvälineistön tarpeesi mukaan, alustamme ohjelmiston ja toimitamme langattomat sensorit sinulle asennettavaksi.</p>
      <h4>Yllättävän edullinen</h4>
      <p>Mittausjärjestelmien kustannukset putoavat keskitetyn alustan ja langattomien sensoreiden avulla huomattavasti, kun etäällä sijaitseville sensoreille ei tarvitse järjestää kaapelointia/nettiyhteyttä/mittauskäyntejä ja käytössä on vain yksi alusta. Yksinkertaisimmat mittaus- ja valvontajärjestelmät kauttamme liikkuvat alle tuhannen euron hintaluokassa.</p>
      <h4>Järjestelmän tuomia etuja</h4>
      <ul>
        <li>Valvoo reaaliaikaisesti ja jatkuvasti</li>
        <li>Ennaltaehkäisee ongelmatilanteita</li>
        <li>Mahdollistaa mittaushistorian tarkastelun</li>
        <li>Tietoa voidaan siirtää myös muihin järjestelmiin</li>
        <li>Etävalvonta säästää resursseja</li>
        <li>Vähentää kasvihuonekaasujen päästöjä</li>
      </ul>
      <h4>Esimerkkejä käyttökohteista</h4>
      <ul>
        <li>Lumisensoreiden avulla ohjaat aurauskalustosi sinne missä on tarve</li>
        <li>Pumppaamoiden, kaivojen, siilojen, roskamolkkien yms. täyttöasteseurannat</li>
        <li>Ilmanlaadun seuranta sekä sisällä että ulkona</li>
        <li>Paine-erojen mittaus ilmanvaihdon säätämisessä</li>
        <li>Kylmälaitteiden sisälämpötilan seuranta</li>
        <li>Rakentamisen sensoroinnit, esim. betonin kosteus</li>
        <li>Edes mielikuvitus ei ole rajana, kerro mittaustarpeesi niin etsimme oikean ratkaisun!</li>
      </ul>
    </div>
  )
}