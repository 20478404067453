import React, {useEffect, useRef, useState} from 'react'
import styles from "../styles/Overview.module.css"
import PoolImage from "../images/pool.svg"
import useWindowDimensions from "../lib/useWindowDimensions"
import AnimatedCanvas from "./AnimatedCanvas"
import Logo from "../classes/Logo"

export default () => {
  const canvasRef = useRef(null)
  const [ elements, setElements ] = useState([])
  const { windowWidth } = useWindowDimensions()

  useEffect(() => {
    initElements()
  }, [])

  useEffect(() => {
    initElements()
  }, [windowWidth])

  const canvasWidth = () => (
    windowWidth * 0.2
  )

  const canvasHeight = () => (
    canvasWidth()
  )

  const initElements = () => {
    const ctx = canvasRef.current.getContext('2d')
    let elements = []
    /* FROM BOTTOM TO TOP */
    elements.push(new Logo({
      ctx: ctx,
      y: canvasHeight() * 0.5 - canvasHeight() * 0.1,
      x: canvasWidth() * 0.5 - canvasWidth() * 0.05,
      width: canvasWidth() * 0.3,
      height: canvasHeight() * 0.3,
      speed: 0.002,
      amplitude: canvasHeight() * 0.05
    }))
    setElements(elements)
  }

  return (
    <div className={styles.wrapper} style={{fontSize: Math.min(Math.max(canvasWidth() * 0.073, 16), 22)}}>
      <div className={styles.animation}>
        <img className={styles.pool}
             src={PoolImage}
             width={canvasWidth() * 2}/>
        <AnimatedCanvas
          ref={canvasRef}
          width={canvasWidth()}
          height={canvasHeight()}
          elements={elements}
          style={{
            position: "absolute",
            top: canvasHeight() * 0.5 + 100,
            paddingRight: 40,
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
        />
      </div>
      <div className={styles.heading}>
        <h1>Ominaisuudet</h1>
      </div>
      <div className={styles.boxOne}>
        <h3>Sensorit</h3>
        <ul>
          <li>Langaton LoRaWAN-tietoliikenne</li>
          <li>Monenlaisia eri mittaustarpeisiin:<br/>
          esim. lämpötila, kosteus, paine</li>
          <li>GPS-paikannus</li>
          <li>Ei tarvitse nettiyhteyttä</li>
          <li>Pitkä käyttöaika paristoilla</li>
        </ul>
      </div>
      <div className={styles.boxTwo}>
        <h3>Monitorit</h3>
        <ul>
          <li>Mobiilisovellus Android ja iOS</li>
          <li>Selainkäyttöliittymä</li>
          <li>Hälytykset email ja sms:<br/>
            esim. raja-arvot, geofence</li>
          <li>Graafinen datan historia</li>
          <li>Karttanäkymä</li>
        </ul>
      </div>
      <div className={styles.boxThree}>
        <h3>Rajapinnat</h3>
        <ul>
          <li>JSON API-standardi</li>
          <li>Julkinen ja yksityinen</li>
          <li>Suodatus mittausajan mukaan</li>
          <li>Tiedostona esim. Exceliin (csv)</li>
          <li>Ad Hoc tarvittaessa</li>
        </ul>
      </div>
      <div className={styles.boxFour}>
        <h3>Kehitys</h3>
        <ul>
          <li>Ketterä ja kustomoitava</li>
          <li>Helppo lisätä uusia sensoreita</li>
          <li>Focus asiakkaan tarpeissa</li>
          <li>Valamme vaikka betonia!</li>
        </ul>
      </div>
    </div>
  )
}