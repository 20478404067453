import React, {useState} from 'react'
import Header from './components/Header'
import Home from "./components/Home"
import Page from './components/Page'
import Contact from "./components/Contact"
import Overview from "./components/Overview"
import Benefits from "./components/Benefits"
import Cases from "./components/Cases"
import Footer from "./components/Footer"
import Partners from "./components/Partners"
import AboutCookies from "./components/AboutCookies"

export default () => {
  const [ showCookiePolicy, setShowCookiePolicy ] = useState(false)

  return (
    <div className="App">
      <Header>
        <Page id="home" first={true}>
          <Home/>
        </Page>
        <Page id="benefits" backgroundColor="#dddef2">
          <Benefits/>
        </Page>
        <Page id="overview" backgroundColor="#f5d6d6">
          <Overview/>
        </Page>
        <Page id="cases" backgroundColor="#54577d">
          <Cases/>
        </Page>
        <Page id="contact" backgroundColor='#f05a73' last={true}>
          <Contact/>
        </Page>
      </Header>
      <Partners/>
      <AboutCookies visible={showCookiePolicy} setVisible={setShowCookiePolicy}/>
      <Footer setShowCookiePolicy={setShowCookiePolicy}/>
    </div>
  )
}
