import React from 'react'
import styles from '../styles/AboutCookies.module.css'

export default ({visible, setVisible}) => {
  return (
    <div className={visible ? styles.popup : styles.popupClosed}>
      <div className={styles.content}>
        <h1>Tietoa evästeistä</h1>
        <h2>Tietoa SensorMonitor-sivuston käyttämistä evästeistä</h2>
        <p>
        Eväste on pieni tekstitiedosto, joka tallennetaan käyttäjän tietokoneelle, kun hän vierailee sivustossa. Evästeet eivät sisällä henkilötietoja eikä niiden avulla voida suorittaa ohjelmia tai tallentaa viruksia käyttäjän tietokoneelle.
        </p>
        <h2>Evästeet tällä sivustolla</h2>
        <p>
        Käytämme evästeitä Googlen Analytics palvelun kävijäseurantaa varten. Seurannan tarkoitus on kerätä tilastotietoa esimerkiksi sivun kävijämäärästä sekä suosituimmista sisällöistä.
        </p>
        <p>
          Lisätietoja Googlen evästeistä voit lukea täältä: <a rel="noopener noreferrer" href="https://www.google.com/policies/technologies/cookies/" target="_blank">Googlen evästekäytäntö</a>. Evästetiedot auttavat meitä parantamaan sivustomme toimivuutta ja käytettävyyttä.
        </p>
        <h2>Evästeiden käytön estäminen</h2>
        <p>
        Jos haluat estää evästeet, muuta selaimesi evästeasetuksia. Lisätietoja: <a rel="noopener noreferrer" href="https://aboutcookies.org" target="_blank">aboutcookies.org</a>.
        </p>
        <p>
        Jos estät evästeiden käytön selainohjelmassasi, laitettasi ei seurata, kun vierailet sivustossa. Huomaa kuitenkin, että estämällä evästeiden käytön saatat estää myös joidenkin sivuston toimintojen käyttämisen.
        </p>
        <p>
          <button className={styles.button} onClick={() => setVisible(false)}>Sulje</button>
        </p>
      </div>
    </div>
  )
}